import React, { useState, useEffect, useRef } from 'react'
import { TailSpin } from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import { CountryAutocomplete } from "./CountryAutocomplete";

import { sendMail } from "../lib/api-lib";
import moment from 'moment';

function CmsBookForm({ component, page_id='' }) {
  const [showModal, setShowModal] = useState(true)
  const [showLoading, setShowLoading] = useState(false)
  const [showResult, setShowResult] = useState(0)
  const [bottomReached, setbottomReached] = useState(false)
  const [privacyWarning, setPrivacyWarning] = useState(false)
  const [checkInMinDate, setCheckInMinDate] = useState(null)
  const [checkOutMinDate, setCheckOutMinDate] = useState(null)
  const [checkInDate, setCheckInDate] = useState('');
  const [checkOutDate, setCheckOutDate] = useState('');
  const [country, setCountry] = useState('');  // unused atm

  const form_data = {
    name: '',
    surname: '',
    email: '',
    phone: '',
    date_from: '',
    date_to: '',
    participants: '',
    billing_address: '',
    country: '',
    message: '',
  };

  useEffect(() => {
    setCheckInMinDate(moment(new Date()).add(1, 'days').format('YYYY-MM-DD'))
    if(checkInDate === '') {
      setCheckOutMinDate(moment(checkInMinDate).add(1, 'days').format('YYYY-MM-DD'))
    } else {
      setCheckOutMinDate(moment(checkInDate).add(1, 'days').format('YYYY-MM-DD'))
      console.log(moment(checkInDate).diff(moment(checkOutDate)) > 0)
      if(checkOutDate !== '' && moment(checkInDate).diff(moment(checkOutDate)) > 0) {
        setCheckOutDate(moment(checkInDate).add(1, 'days').format('YYYY-MM-DD'))
      }
    }

  }, [checkInMinDate, checkOutMinDate, checkInDate, checkOutDate])

  const handleSubmit = async (event) => {
    // console.log(event);
    event.preventDefault();

    if(bottomReached) {

      const data = {
        name: event.target.name.value,
        surname: event.target.surname.value,
        email: event.target.email.value,
        phone: event.target.phone.value,
        date_from: event.target.date_from.value,
        date_to: event.target.date_to.value,
        participants: event.target.participants.value,
        billing_address: event.target.billing_address.value,
        country: event.target.country.value,
        subject: "Message from website",
        message: event.target.message.value
      };

      setShowLoading(true);

      fetch(process.env.BASE_URL + "/api/sendbookrequest", {
        body: JSON.stringify(data),
        method: "POST",
      })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        setShowLoading(false);
        if (data.response == 1) {
          setShowResult(1);
        } else {
          setShowResult(2);
        }
      })
      .catch((error) => {
        setShowLoading(false);
        setShowResult(2);
      });
    }
  };

  const closeForm = () => {
    document.querySelector("body, html").classList.remove("no-scroll");
    setShowModal(false);
    setShowResult(0);
  };

  const listInnerRef = useRef();

  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        setbottomReached(true);
        // console.log("reached bottom");
      }
    }
  };

  return (
    <div>
      {showModal ? (
        <div>
          <div className="contact-form">
            <div className="contact-form-container relative">
              {showResult == 0 ? (
                <div>
                  {showLoading ? (
                    <div className="absolute w-full h-full theme-b5 z-10 flex flex-col justify-center items-center">
                      <TailSpin
                        type="Puff"
                        color="#15A1b1"
                        height={70}
                        width={70}
                        timeout={3000}
                      />
                      <div>sending...</div>
                    </div>
                  ) : (
                    ""
                  )}
                  {/*content*/}
                  <form className="w-full" onSubmit={handleSubmit}>
                    <div className="form-contact">
                      {/*body*/}
                      <div className="relative p-6 flex-auto">
                        <div className="form-row grid md:grid-cols-2 gap-4">
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              First Name <span className="required">*</span>
                            </label>
                            <input
                              id="form_name"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Name (required)"
                              name="name"
                              defaultValue={form_data.name}
                              required
                            />
                          </div>
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_surname"
                            >
                              Surname <span className="required">*</span>
                            </label>
                            <input
                              id="form_surname"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Surname (required)"
                              name="surname"
                              defaultValue={form_data.surname}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row grid md:grid-cols-2 gap-4">
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_phone"
                            >
                              Phone
                            </label>
                            <input
                              id="form_phone"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Phone"
                              defaultValue={form_data.phone}
                              name="phone"
                            />
                          </div>
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_email"
                            >
                              Email <span className="required">*</span>
                            </label>
                            <input
                              id="form_email"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Email (obbligatorio)"
                              name="email"
                              defaultValue={form_data.email}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row grid md:grid-cols-3 gap-4">
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              Check-in date <span className="required">*</span>
                            </label>
                            <input
                              id="date_from"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="date"
                              placeholder=""
                              aria-label="Check-in date (required)"
                              name="date_from"
                              onChange={ event => {
                                setCheckInDate(event.target.value);
                              }}
                              min={checkInMinDate}
                              value={checkInDate}
                              required
                            />
                          </div>
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              Check-out date <span className="required">*</span>
                            </label>
                            <input
                              id="date_to"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="date"
                              placeholder=""
                              aria-label="Check-out date (required)"
                              name="date_to"
                              onChange={ event => {
                                setCheckOutDate(event.target.value);
                              }}
                              min={checkOutMinDate}
                              value={checkOutDate}
                              required
                            />
                          </div>
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              Participants <span className="required">*</span>
                            </label>
                            <input
                              id="participants"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="number"
                              placeholder=""
                              aria-label="Participants (required)"
                              name="participants"
                              defaultValue={form_data.participants}
                              required
                            />
                          </div>
                        </div>
                        <div className="form-row grid md:grid-cols-2 gap-4">
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              Billing address <span className="required">*</span>
                            </label>
                            <input
                              id="billing_address"
                              className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                              type="text"
                              placeholder=""
                              aria-label="Billing address (required)"
                              name="billing_address"
                              defaultValue={form_data.billing_address}
                              required
                            />
                          </div>
                          <div className="form-fld py-4">
                            <label
                              className="theme-c2 font-bold"
                              htmlFor="form_name"
                            >
                              Country <span className="required">*</span>
                            </label>
                            <CountryAutocomplete
                              name="country"
                              // defaultValue={form_data.country}
                              required={true}
                              // setModelValue={(val) => form_data.country = val}
                            />
                          </div>
                        </div>
                        <div className="form-row py-4">
                          <label
                            className="theme-c2 font-bold"
                            htmlFor="form_message"
                          >
                            Message
                          </label>
                          <textarea
                            id="form_message"
                            className="appearance-none bg-transparent border-none w-full h-32 text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
                            type="text"
                            placeholder=""
                            aria-label="Messaggio"
                            name="message"
                            defaultValue={form_data.message}
                          ></textarea>
                        </div>
                        <div className="text-sm text-center mb-2">
                          <h4 className="theme-c2 text-xl ">scroll to the bottom to read and accept all policies</h4>
                        </div>
                        <div className="bg-white px-8 py-6 overflow-auto h-32"
                          onScroll={onScroll}
                          ref={listInnerRef}
                        >
                          <div className="text-sm">
                            <div 
                                dangerouslySetInnerHTML={{ __html: component.options.content }}
                            />
                          </div>
                        </div>
                      </div>
                      {/*footer*/}

                      <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b relative">
                        {!bottomReached ? 
                          <div className="w-full h-full z-50 absolute top-0 left-0"></div>
                        : '' }
                        <input type="checkbox" value="1" id="form_privacy" name="privacy" className="mr-4 cursor-pointer"
                        // disabled={!bottomReached}
                        required
                        />
                          
                          <label
                            className={`font-bold cursor-pointer ${!bottomReached ? 'theme-c3' : ''} `}
                            htmlFor="form_privacy"
                          >
                            {`I've read and accepted all above policies`}
                          </label>
                      </div>
                      <div className="flex items-center justify-center pb-6 border-t border-solid border-slate-200 rounded-b relative">
                        <input type="checkbox" value="1" id="form_terms" name="terms" className="mr-4 cursor-pointer"
                        required
                        />
                          
                          <label
                            className={`font-bold cursor-pointer`}
                            htmlFor="form_terms"
                          >
                            I&apos;ve read and accepted all <a href="/terms-of-use" target="_blank">Terms of use</a>
                          </label>
                      </div>
                      <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                        <button className="externalBtn" type="submit">
                          SEND MESSAGE
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              ) : (
                <div>
                  <div className="form-cms-container-inner">
                    <div className="relative p-6 flex-auto">
                      <div className="z-10 flex flex-col justify-center items-center">
                        {showResult == 1 ? (
                          <div>
                            <h5 className="theme-c2 text-center">
                              Thanks for contacting us!
                            </h5>
                            <p className="font-normal text-center">
                              We will respond to your request as soon as
                              possible
                            </p>
                            {/* <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div> */}
                          </div>
                        ) : (
                          <div>
                            <h5 className="text-red-600 text-center">
                              An error has occurred!
                            </h5>
                            <p className="font-normal mt-8 text-center">
                              We apologize for any inconvenience.
                              <br />
                              Please contact us by writing to{" "}
                              <a
                                href="mailto:tarponville@yahoo.com"
                                className="theme-c2"
                              >
                                tarponville@yahoo.com
                              </a>
                            </p>
                            <div className="flex items-center justify-center p-6 border-t border-solid border-slate-200 rounded-b">
                              <button className="externalBtn" type="button" onClick={() => { setShowResult(0) }}>
                                RETRY
                              </button>
                            </div>
                            {/* <div className="mt-8 mb-12">
                              <button
                                className="sendBtn"
                                type="button"
                                onClick={(e) => {
                                  e.preventDefault();
                                  closeForm();
                                }}
                              >
                                Chiudi
                              </button>
                            </div> */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default CmsBookForm;
