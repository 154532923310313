// https://codesandbox.io/p/sandbox/vibrant-field-mmrow?file=%2Fsrc%2FAutocompletePureBase.tsx%3A27%2C13
import { useCallback, useState } from "react";
import { AutocompletePure } from "react-autocomplete-pure";
import countries from '../lib/countries'

const theme = {
  list: "list",
  item: "item"
};

const fetchResults = (query) => {
  const filteredItems = countries.filter(({ name }) =>
    name.toLowerCase().includes(query.toLowerCase())
  );

  return new Promise((resolve) => {
    setTimeout(() => resolve(filteredItems), 500);
  });
};

const renderItem = (item, { isHighlighted }) => (
  <div className={`item ${isHighlighted ? 'highlight' : ''}`}>{item.name} ({item.abbr})</div>
);

const getSuggestionValue = (item) => `${item.name} (${item.abbr})`;

export function CountryAutocomplete(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [value, setValue] = useState('');

  // When inpute changes save value.
  // If change reason is type on input then get new items, save them and close dropdown if no new items fetched
  // If change reason is enter keydown then simple close dropdown
  const handleChange = useCallback(
    async (_event, { value, reason }) => {
      setValue(value);
      if (reason === "INPUT") {
        const newItems = await fetchResults(value);
        setSuggestions(newItems);
        setIsOpen(Boolean(newItems.length));
      } else if (reason === "ENTER") {
        setIsOpen(false);
      }
    },
    []
  );

  // When item selected then save it and close dropdown
  const handleSelect = useCallback(
    (_event, { item }) => {
      const value = getSuggestionValue(item);
      setValue(value);
      setIsOpen(false);
    },
    []
  );

  // Close dropdown when clicked outside of component
  const handleClickOutside = (_event) => {
    const ismatch= countries.some((c) => `${c.name} (${c.abbr})` === value)
    if(!ismatch) setValue('')
    setIsOpen(false);
  };

  return (
    <div className="autocomplete">
      <AutocompletePure
        id="react-autocomplete-pure"
        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 leading-tight focus:outline-none"
        open={isOpen}
        value={value}
        items={suggestions}
        renderItem={renderItem}
        getSuggestionValue={getSuggestionValue}
        onChange={handleChange}
        onSelect={handleSelect}
        onClickOutside={handleClickOutside}
        theme={theme}
        {...props}
      />
    </div>
  );
}
